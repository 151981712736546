import * as React from "react"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <div className="page-content">
      <header className="upper-content">
        <div className="header-content">
          <h1>404: Not Found</h1>
        </div>
      </header>
      <main className="lower-content">
        <div className="not-found">
          <h4>That page doesn&#39;t exist...</h4>
        </div>
      </main>
    </div>
  </>
)

export default NotFoundPage
